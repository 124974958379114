<template>

  <div
      class="mt-30 text-left flex-row"
  >
    <MypageSide
        :program="program"
        :user="user"
    ></MypageSide>

    <div
        class="flex-1 bg-white pa-24 radius-20"
    >
      <div>
        <div class="under-line-bbb pb-15 flex-row">
          <h3 class="flex-1 size-px-20">{{ program.title }}</h3>
        </div>

      </div>
      <!-- //친구검색 -->
    </div>
    <!-- //고정해더 -->
    <!-- 컨텐츠 내용 -->
    <Friends
        v-if="friends_popup"
        :user="user"

        @cancel="$router.back()"
    ></Friends>

  </div>
</template>

<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
import Friends from "@/view/Mypage/popup/Friends";
export default {
  name: 'mafia102-1'
	, components: {MypageSide,Friends}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '친구 '
				, title: '친구'
				, type: 'mypage'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, items_member: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				seach_type: ''
				, search_value: ''
			})
			, is_confirm: false
			, item_friend: {}
			, friends_popup: true
		}
  }
  ,async created() {
    this.$bus.$emit('onLoad', this.program)
    //await this.getCartel()
  }

}
</script>

<style>
</style>

